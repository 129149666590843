<div mat-dialog-title class="dialog-title">
  <span>{{ 'common.confirm' | translate }}</span>
</div>
<div mat-dialog-content id="confirm-action-by-password">
  <form [formGroup]="passwordForm">
    <div fxLayout fxLayoutAlign="space-around center" fxLayout.lt-md="column">
      <mat-form-field>
        <input
          matInput
          id="confirm-action-by-password-input-password"
          [type]="showPassword ? 'text' : 'password'"
          placeholder="{{ 'common.confirm_action_by_password' | translate }}"
          [formControlName]="'password'" />
        <mat-error id="confirm-action-by-password-input-password-error-required">{{
          'common.required_field' | translate
        }}</mat-error>
        <mat-icon
          class="cursor-pointer"
          id="confirm-action-by-password-button-show-password"
          matSuffix
          (click)="showHidePassword()"
          [ngStyle]="{ color: showPassword ? '#03a9f4' : '#5C555555' }"
          >{{ showPassword ? 'remove_red_eye' : 'visibility_off' }}
        </mat-icon>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayout fxLayoutAlign="space-between center">
  <button
    mat-stroked-button
    mat-dialog-close
    color="warn"
    (click)="cancel()"
    id="confirm-action-by-password-button-cancel">
    {{ 'common.cancel' | translate }}
  </button>
  <button
    id="confirm-action-by-password-button-confirm"
    mat-flat-button
    color="accent"
    [disabled]="passwordForm.invalid"
    (click)="onSubmit()">
    OK
  </button>
</div>
