import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { StorageService } from '../../../core/services/storage.service';
import { Agent, TAgent } from '../../../modules/hr/models/agent.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthResponse } from '../../../modules/auth/models/auth.model';
import { TokenDataModel } from 'app/shared/models/token-data.model';
import { applicationLangs } from '../../constants/language';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
})
export class HeaderSideComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  authToken: any;
  isAuthenticated = false;
  user: Agent;

  @Input() notificPanel;
  @Input() notifsNumber;
  availableLangs = applicationLangs;
  currentLang = this.availableLangs[0];

  tokenData: TokenDataModel;
  agentsLogins: TAgent[];
  agentLoginsSelected: TAgent;

  public egretThemes;
  public layoutConf: any;

  constructor(
    private toastrService: ToastrService,
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.initAgentsLogins();
  }

  private initAgentsLogins() {
    this.tokenData = this.authService.getTokenData();
    this.agentsLogins = this.tokenData.agents_logins
      ? this.tokenData.agents_logins
      : [new TAgent(this.tokenData.network, this.tokenData.agent_id, this.tokenData.agent_username)];
    this.agentLoginsSelected = this.agentsLogins.find(agentLogin => agentLogin.network === this.tokenData.network);
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: 'full',
          sidebarCompactToggle: false,
        },
        { transitionClass: true },
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: 'compact',
        sidebarCompactToggle: true,
      },
      { transitionClass: true },
    );
  }

  onSearch(e) {}

  logOut() {
    this.authService
      .logOut()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data) {
            this.authToken = null;
            this.user = null;
            this.isAuthenticated = false;
            this.storageService.clear();
            this.router.navigate(['/auth/signin']);
          }
        },
        err => {
          this.toastrService.error(err, 'Erreur!');
          console.error(err);
        },
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onChangeNetwork(newAgent: TAgent, oldAgent: TAgent) {
    this.agentLoginsSelected = newAgent;
    this.authService
      .generateToken(oldAgent, newAgent)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: AuthResponse) => {
          this.authService.storeUserData(data.token);
          const url = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([url]));
        },
        err => {
          this.toastrService.error(err, 'Erreur!');
          console.error(err);
        },
      );
  }
}
