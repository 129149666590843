import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';

@Injectable()
export class UploadService {
  constructor(private apiService: ApiService, private http: HttpClient, private configService: ConfigService) {}

  uploadSingle(images) {
    const formData = new FormData();
    formData.append('upload', images, 'picture.png');
    return this.post('/single', formData);
  }

  uploadMulti(images: any) {
    const formData = new FormData();
    for (let index = 0; index < images.length; index++) {
      formData.append('images[]', images[index]);
    }
    return this.post('/multi', formData);
  }

  private post(url, formData) {
    const fullUrl = this.makeFullUrl(url);
    const headers = this.makeHeaders();
    return this.http.post(fullUrl, formData).pipe(catchError(this.formatErrors));
  }

  private makeFullUrl(url: string): string {
    return this.configService.readConfig().apiUrl + '/upload' + url;
  }

  makeHeaders() {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return { headers: new HttpHeaders(headers) };
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const bb = new Blob([ab]);
    return bb;
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  fullUrl(url: string): string {
    if (url) return `${this.configService.readConfig().apiUrl}/${url}`;
    return null;
  }
}
