import { EditTripResponse, RouteRepository } from '@drivers';
import { ApiService } from '../../../core/services/api.service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Route } from '../models/route';

export class RouteHttpRepository implements RouteRepository {
  private readonly apiService = inject(ApiService);
  private url = '/bus-planning';

  getRoutesByDate(date: string): Observable<Route[]> {
    return this.apiService.get(this.url, { date });
  }

  updateTrip(params: {
    routeId: string;
    tripId: string;
    agentId: number | null;
  }): Observable<EditTripResponse | undefined> {
    const url = `${this.url}/edit-trip`;
    return this.apiService.patch(url, params);
  }
}
