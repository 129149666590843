import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NetworkparamsService {
  private url = '/network-params';

  constructor(private apiService: ApiService, private translateService: TranslateService) {}

  /** GET: get Network params from the server */
  get(options?) {
    return this.apiService.get(this.url, options);
  }
  getNetworksOfSuperNetwork(superNetwork: string) {
    const endpoint = `${this.url}/sub-networks-of`;
    return this.apiService.get(endpoint, { superNetwork });
  }
  /** POST: create a new Network params to the server */
  create(options?) {
    return this.apiService.post(this.url, options);
  }
  /** PUT: update Network params on the server */
  update(options?) {
    const lang = this.translateService.currentLang
      ? this.translateService.currentLang
      : this.translateService.defaultLang;
    options = { ...options, lang };
    return this.apiService.put(this.url, options);
  }
  /** DELETE: delete Network params from the server */
  delete(options?) {
    return this.apiService.delete(this.url, options);
  }
}
