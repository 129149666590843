export enum Regex {
  PHONE_REGEX = '((?:\\+|00)[17](?: |\\-)?|(?:\\+|00)[1-9]\\d{0,2}(?: |\\-)?|(?:\\+|00)1\\-\\d{3}(?: |\\-)?)?(0\\d|\\([0-9]{3}\\)|[1-9]{0,3})(?:((?: |\\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\\-)[0-9]{3}(?: |\\-)[0-9]{4})|([0-9]{7}))',
  NUMBER_REGEX = '^[0-9]*$',
  PASSWORD_REGEX = '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,40})',
  FLOAT_NUMBER_REGEX = '[-,+]?\\d+([.]\\d+)?',
  POSITIVE_NUMBER = '\\d+',
  FLOAT_POSITIVE_NUMBER = '\\d+([.]\\d+)?',
  ENCODAGE_PERCENT = "[:/?#\\[\\]@!\\$&'\\(\\)*\\+,;=% ]",
  TIME_hh_mm_ss = '^([01][\\d]|2[0-3]):([0-5]\\d):([0-5]\\d)$',
  TIME_HH_mm = '^([01][0-9]|2[0-3]):[0-5][0-9]$',
  AGENT_NAME = "[A-Za-z'éèêÉÈÊ\\-_ ]*",
  AGENT_USERNAME = '[a-zA-Z0-9@\\.]*',
  ALPHANUMERIC_WITH_DASH_AND_UNDERSCORE = '[\\w-]*',
  NO_WHITE_SPACE = '[^\\s]+',
}
