export const environment = {
  version: require('../../package.json').version,
  production: true,
  /*production: false,
  apiUrl: 'http://localhost:8004/api',
  mqttServer: {
    hostname: 'localhost',
    port: 1885,
    path: '',
    protocol: protocol,
  },*/
};
