<div class="text-center mat-bg-primary pt-1 pb-1">
  <h6 class="m-0">Notifications</h6>
</div>
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item
    *ngFor="let notif of notifications; let i = index"
    class="notific-item"
    role="listitem"
    routerLinkActive="open">
    <div
      class="mat-list-item-content notific-item"
      [ngClass]="notif.clicked ? 'mat-list-item-content-clicked' : 'mat-list-item-content-not-clicked'">
      <mat-icon [ngStyle]="{ color: notif.color }" class="notif-icon">{{ notif.icon }}</mat-icon>
      <a [routerLink]="[notif.route]" (click)="onNotificationClick(notif, i)">
        <div class="mat-list-text">
          <h4 class="message">{{ notif.message }}</h4>
          <small class="time text-muted">{{ notif.time }}</small>
        </div>
      </a>
    </div>
  </mat-list-item>
</mat-nav-list>
<div class="text-center mt-1" *ngIf="notifications.length">
  <small
    ><a href="#" (click)="clearAll($event)">{{ 'incidents.clear_notifs' | translate }}</a></small
  >
</div>
