import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Notification } from '../../models/notification.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;
  @Input() notifications: Notification[];
  @Input() notifsNumber;
  @Output() updateNotificationsArray = new EventEmitter<any>();
  @Output() updateNotifNumber = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(routeChange => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
  }
  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
    this.updateNotificationsArray.emit(this.notifications);
    this.updateNotifNumber.emit(this.notifications.length);
  }

  onNotificationClick(notif, index) {
    this.notifications[index].clicked = true;
    this.updateNotifNumber.emit(this.notifications.filter(notif => !notif.clicked)?.length);
    this.router.navigate([this.notifications[index].route]);
  }
}
