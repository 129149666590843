import { Injectable } from '@angular/core';
import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MqttWrapperService {
  constructor(private mqttService: MqttService) {}

  connect(options: IMqttServiceOptions) {
    this.mqttService.connect(options);
  }

  observe(topic: string): Observable<any> {
    return this.mqttService.observe(topic);
  }
}
