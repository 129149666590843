import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigService } from '../../shared/services/config.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private storageService: StorageService, private configService: ConfigService) {}

  get(url: string, params: any = {}, responseType?): Observable<any> {
    const fullUrl = this.makeFullUrl(url);
    params = this.makeFullBody(params);
    return this.http.get(fullUrl, { params, responseType }).pipe(catchError(this.formatErrors));
  }

  patch(url: string, body = {}): Observable<any> {
    const fullUrl = this.makeFullUrl(url);
    body = this.makeFullBody(body);
    return this.http.patch(fullUrl, body, httpOptions).pipe(catchError(this.formatErrors));
  }

  post(url: string, body = {}, responseType?): Observable<any> {
    const fullUrl = this.makeFullUrl(url);
    body = this.makeFullBody(body);
    return this.http.post(fullUrl, body, { responseType }).pipe(catchError(this.formatErrors));
  }

  put(url: string, body = {}): Observable<any> {
    const fullUrl = this.makeFullUrl(url);
    body = this.makeFullBody(body);
    return this.http.put(fullUrl, body, httpOptions).pipe(catchError(this.formatErrors));
  }

  delete(url: string, params: any = {}): Observable<any> {
    const fullUrl = this.makeFullUrl(url);
    params = this.makeFullBody(params);
    return this.http.delete(fullUrl, { params }).pipe(catchError(this.formatErrors));
  }

  private makeFullUrl(url: string): string {
    return this.configService.readConfig().apiUrl + url;
  }

  private makeFullBody(body?: any): any {
    const helper = new JwtHelperService();
    const token = helper.decodeToken(this.storageService.get('token'));
    if (token) {
      return { ...body, network: token.network };
    } else {
      return body;
    }
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
