import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { latLng, Layer, tileLayer } from 'leaflet';
import { NetworkparamsService } from '../../../modules/hr/services/networkparams.service';
import { TranslateService } from '@ngx-translate/core';
import { LeafletLayersModel } from '../../models/leaflet-layers.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import 'leaflet.markercluster';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.scss'],
})
export class MapviewComponent implements OnChanges, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() mapData: any;
  @Input() isDisplayMap: boolean;
  @Input() showLegend: boolean;
  @Input() dynamicPopup: boolean;
  @Input() getLatLng: boolean;
  @Input() showTeamLegend: boolean;
  @Output() mapUpdate = new EventEmitter<boolean>();
  @Output() mapDataChangedEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() teamData: string;
  @Input() clusterMarkersData: any;
  @Input() type: string; // to identify from which component the map is called

  labels = [];
  legend: any;
  public map: L.Map;

  // Open Street Map and Open Cycle Map definitions
  LAYER_OSM = {
    id: 'openstreetmap',
    name: 'Open Street Map',
    enabled: true,
    layer: tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: 'Open Street Map',
    }),
  };
  LAYER_ESRI = {
    id: 'esri',
    name: 'Esri',
    enabled: true,
    layer: L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 18,
        attribution:
          'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      },
    ),
  };

  // Form models object
  model = new LeafletLayersModel(
    [this.LAYER_OSM, this.LAYER_ESRI],
    this.LAYER_OSM.id,
    // , [ this.circle, this.polygon, this.square, this.geoJSON ]
  );

  // Values to bind to Leaflet Directive
  layers: Layer[];
  layersControl = {
    baseLayers: {
      'Open Street Map': this.LAYER_OSM.layer,
      'Esri World Imagery': this.LAYER_ESRI.layer,
    },
  };
  center;
  zoom;

  defaultIconObject = L.icon({
    iconUrl: '/assets/images/map-view-icons/marker-icon-2x.png',
    iconSize: [25, 43],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

  constructor(private networkParamsService: NetworkparamsService, private translateService: TranslateService) {
    this.apply();
  }

  apply() {
    this.networkParamsService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        params => {
          this.zoom = 10;
          this.center = latLng([params[0].map_center_lat, params[0].map_center_lon]);
        },
        error => {
          console.error(error);
        },
      );

    // Get the active base layer
    const baseLayer = this.model.baseLayers.find((l: any) => l.id === this.model.baseLayer);

    // Get all the active overlay layers
    const newLayers = this.model.overlayLayers.filter((l: any) => l.enabled).map((l: any) => l.layer);
    newLayers.unshift(baseLayer.layer);

    this.layers = newLayers;

    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onMapReady(map: L.Map) {
    this.map = map;
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
    if (this.getLatLng) {
      this.map.on('click', this.onMapClick.bind(this));
    }
    if (this.showLegend) {
      this.getStatusesAndDisplayLegend(this.type);
      // when the language is changed, the statuses in the legend have to be updated
      this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(
        () => {
          this.getStatusesAndDisplayLegend(this.type);
        },
        error => {
          console.error(error);
        },
      );
    }
    if (this.dynamicPopup) {
      this.getPopupTranslateLables(this.type);
      // when the language is changed, the statuses in the legend have to be updated
      this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(
        () => {
          this.getPopupTranslateLables(this.type);
        },
        error => {
          console.error(error);
        },
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapData && this.mapData) {
      const mapData = JSON.parse(this.mapData);
      if (mapData.features) {
        this.removeLayers();
        this.displayMapData();
      }
    } else if (changes.teamData && this.teamData) {
      const teamData = JSON.parse(this.teamData);
      if (teamData.features) {
        this.removeTeamLayers();
        this.displayAllTeams();
      }
    } else if (changes.clusterMarkersData && this.clusterMarkersData) {
      const clusterMarkersData = JSON.parse(this.clusterMarkersData);
      if (clusterMarkersData.features) {
        this.displayMarkerClusterGroups(clusterMarkersData);
      }
    } else if (changes.isDisplayMap && this.isDisplayMap) {
      this.map.invalidateSize();
    }
  }

  onMapClick(e) {
    this.removeLayers();
    new L.Marker(e.latlng, { icon: this.defaultIconObject })
      .addTo(this.map)
      // .bindPopup(e.latlng.toString())
      .openPopup();
    this.center = latLng(e.latlng.lat, e.latlng.lng);
    this.mapDataChangedEmitter.emit(e.latlng);
  }

  private removeLayers() {
    if (this.map) {
      this.map.eachLayer(layer => {
        if (!layer.hasOwnProperty('_container')) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  // to remove only the team layers to prevent display refresh on each socket
  private removeTeamLayers() {
    if (this.map) {
      this.map.eachLayer(layer => {
        if (
          layer.hasOwnProperty('options') &&
          layer['options']['icon'] &&
          layer['options']['icon']['options'] &&
          layer['options']['icon']['options']['teamMarker']
        ) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  /**
   * New way to display data based on neuropolis project
   * Change shift to display them
   */
  private displayMapData() {
    const mapDataObj = JSON.parse(this.mapData);
    if (mapDataObj && mapDataObj.features.length) {
      mapDataObj.features.forEach(data => {
        if (data.geometry && data.geometry.type === 'Polyline') {
          this.displayPolyline(data);
        } else if (data.geometry && data.geometry.type === 'Circle') {
          this.displayCircle(data);
        } else if (data.geometry && data.geometry.type === 'Marker') {
          this.displayMarker(data);
        } else if (data.geometry && data.geometry.type === 'CoordPickerMarker') {
          this.displayCoordPickerMarker(data);
        } else if (data.geometry && data.geometry.type === 'Team') {
          this.displayMarker(data);
        } else if (data.geometry && data.geometry.type === 'Polygon') {
          this.displayZone(data);
        }
      });
    }
  }

  private displayPolyline(data) {
    const marker = L.polyline(data.geometry.coordinates, data.properties.options);
    marker.on('click', () => {
      marker.bringToFront(); // to highlight the itinerary with mouse click
    });

    const info = data.properties.info;
    if (info) {
      let popup = '';
      if (info.title) {
        popup += `<div style="text-align: center; bottom: 13px; border-bottom: solid 2px #1c2831; position: relative;">
            <span class="name" style="color: #25aae1; "> ${info.title} </span>
         </div>`;
      }
      if (info.name) {
        popup += `<div>
         <span style="color: white;">${info.name}</span>
          </div>`;
      }
      marker.bindPopup(popup, { className: 'popupCustom' });
    }
    this.layers.push(marker);
  }

  private displayCircle(data) {
    const marker = L.circle(data.geometry.coordinates, data.properties.options);
    const info = data.properties.info;
    if (info) {
      let popup = '';
      if (info.title) {
        popup += `<div style="text-align: center; bottom: 13px; border-bottom: solid 2px #1c2831; position: relative;">
            <span class="name" style="color: #25aae1; "> ${info.title} </span>
         </div>`;
      }
      if (info.numberOfControls) {
        popup += `<div>
          <i class="fa fa-check-square-o" aria-hidden="true" style="color: #25aae1"></i>
          <span style="color: white;"> ${this.translateService.instant('Control_Statistics.number_controls')} : ${
          info.numberOfControls
        }</span>
          </div>`;
      }
      if (info.name) {
        popup += `<div>
           <span style="color: white;">${info.name}</span>
            </div>`;
      }
      marker.bindPopup(popup, { className: 'popupCustom' });
    }
    this.layers.push(marker);
  }

  private displayLegend(type) {
    // create a legend of number of controle by station
    if (this.legend) {
      this.map.removeControl(this.legend);
    }
    this.legend = new L.Control({ position: 'bottomright' });
    if (type == 'team') {
      this.legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info-legend');
        // to translate => {{'Map_view.Number_of_control | translate '}}</br> {{'Map_view.by_station | translate '}}

        // add legend title
        div.innerHTML = `<div class="legend-title"><b> ${this.labels['General_View.team_statuses']} </b></div>`;
        div.innerHTML += `<div class="icons">
                                  <img src="assets/agents-icons/Agents_on_duty.svg" alt="Image for on duty" > ${this.labels['status.on_duty']}
                          <br>    <img src="assets/agents-icons/Agents_off_duty.svg" alt="Image for off duty" > ${this.labels['status.off_duty']}
                           <br>    <img src="assets/agents-icons/Agents_on_break.svg" alt="Image for on break"> ${this.labels['status.on_break']}
                          <br>    <img src="assets/agents-icons/Agents_join_operation.svg" alt="Image for joint operation"> ${this.labels['status.joint_operation']}
                          <br>    <img src="assets/agents-icons/Agents_accident.svg" alt="Image for accident"> ${this.labels['status.accident']}
                        </div>
                        <style>
                          img{
                              height:25px;
                          }
                        </style>`;
        return div;
      };
    } else if (type == 'geo') {
      this.legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info-legend');
        // add legend title
        div.innerHTML = `<div class="legend-title"><b> ${this.translateService.instant(
          'Control_Statistics.fraud_rate',
        )} </b></div>`;
        div.innerHTML += `
        <div class="geo-legend-content">
        <i style="background:#d6322d"> </i> <span>70% - 100%</span><br>
        <i style="background:#f17e3a"> </i> <span>30% - 70%</span><br>
        <i style="background:#49a02e"> </i> <span>0% - 30%</span><br>
        <i style="background:#9b9b9b"> </i> <span>${this.translateService.instant(
          'Control_Statistics.no_controls',
        )}</span><br>
        </div>
        `;
        return div;
      };
    }
    this.legend.addTo(this.map);
  }

  private displayCoordPickerMarker(data) {
    // To set custom icon add 'icon: { options : ...}}' on the 'properties' key on the marker of the component
    setTimeout(() => {
      const icon = data.properties.icon ? L.icon(data.properties.icon.options) : this.defaultIconObject;
      const marker = L.marker(data.geometry.coordinates, { icon, ...data.properties.options });
      this.layers.push(marker);
      // this.center = latLng(data.geometry.coordinates[0], data.geometry.coordinates[1]);
      this.map.panTo(new L.LatLng(data.geometry.coordinates[0], data.geometry.coordinates[1]));
    }, 100);
  }

  private displayMarker(data) {
    // To set custom icon add 'icon: { options : ...}}' on the 'properties' key on the marker of the component
    let icon;
    const optionsIcon = data.properties.icon.options;
    if (data.properties.info.is_pulse) {
      optionsIcon.className = 'pulse-marker';
    }
    if (optionsIcon.iconUrl) {
      icon = L.icon(optionsIcon);
    } else if (optionsIcon.html) {
      optionsIcon.html = L.Util.template(optionsIcon.html, {});
      icon = icon = L.divIcon(optionsIcon);
    } else {
      icon = icon = this.defaultIconObject;
    }
    // const icon = data.properties.icon ? L.icon(data.properties.icon.options) : this.defaultIconObject;
    const marker = L.marker(data.geometry.coordinates, { icon, ...data.properties.options });
    const info = data.properties.info;
    if (info) {
      let popup = '';
      if (info.title) {
        popup += `<div style="text-align: center; border-bottom: solid 2px #1c2831; position: relative;">
            <span class="name" style="color: #25aae1; "> ${info.title} </span>
         </div>`;
      }
      if (info.name) {
        popup += `<div>
         <span style="color: white;">${info.name}</span>
          </div>`;
      }
      if (info.timeline) {
        popup = this.extractTimeline(info, popup);
      }
      if (info.detail_action && /\S/.test(info.detail_action)) {
        popup += `<div style="border-top: solid 2px #1c2831; padding: 2px 0;">
         <span style="color: white; white-space: break-spaces; width: 100%;">${info.detail_action}</span>
          </div>`;
      }
      if (info.team_members && info.team_members.length) {
        popup += `<div style="display:flex; align-items: flex-start; column-gap: 5px;">
            <div style="display:flex; flex-direction: column; align-items: stretch; padding-right: 5px;">
                <span style="text-align: center; color: white;">${info.team_members[0].team_name.toUpperCase()}</span>
                <hr style="width:100%; height:1px; background-color: white; ">
            `;
        for (const member of info.team_members) {
          const color = member.agent_online ? 'green' : 'white';
          popup += `<span style="color:white;">
                            ${member.agent_fullname.toUpperCase()}
                            <span style ="height: 10px; width: 10px; background-color: ${color}; border-radius: 5px; display: inline-block; margin-left: 2px;"></span>
                       </span>`;
        }
        popup += '</div></div>';
      }
      if (info.type_incident_name) {
        this.getPopupTranslateLables(this.type);
        popup += `<div>
         <div class="incident-div">
            <span style="color: white;"><b>${this.labels['incidents.incident_type']}</b></span>
            <span style="color: white;">${info.type_incident_name}</span>
         </div>

         <div class="incident-div">
            <span style="color: white;"><b>${this.labels['incidents.status']}</b></span>
            <span style="color: white;">${info.status_name}</span>
         </div>

         <div class="incident-div">
            <span style="color: white;"><b>${this.labels['incidents.description']}</b></span>
            <span style="color: white;">${info.description}</span>
         </div>
         </div>`;
      }
      if (info.mandatory_stop?.title) {
        popup += `<div style="border-top: solid 2px #1c2831; padding: 2px 0;">
         <span style="color: ${info.mandatory_stop.color ?? 'white'}; width: 100%;">
            ${info.mandatory_stop.title}
         </span></div>`;
      }
      marker.bindPopup(popup, { className: 'popupCustom', maxWidth: 500 });
    }
    this.layers.push(marker);
  }

  private extractTimeline(info, popup: string) {
    if (typeof info.timeline === 'string') {
      popup += `<div>
                <i class="fa fa-clock-o" aria-hidden="true" style="color: #25aae1"></i>
                <span style="color: white;"> ${info.timeline}</span>
                </div>`;
    } else {
      for (const timeline of info.timeline) {
        const colorItinerary = timeline[2] ? timeline[2] : null;
        popup += `<div>
                <i class="fa fa-clock-o" aria-hidden="true" style="color: #25aae1"></i>
                <span style="color: white;"> ${timeline[0].substring(
                  0,
                  timeline[0].length - 3,
                )} - ${timeline[1].substring(0, timeline[0].length - 3)}</span>`;
        if (colorItinerary) {
          popup += `<span style ="height: 10px; width: 10px; background-color: ${colorItinerary}; border-radius: 5px; display: inline-block; margin-left: 5px;"></span>`;
        }
        popup += '</div>';
      }
    }
    return popup;
  }

  private displayAllTeams() {
    const teamData = JSON.parse(this.teamData);
    teamData.features.forEach(team => {
      this.displayMarker(team);
    });
  }

  getStatusesAndDisplayLegend(type) {
    let requiredlabels = [];
    if (type == 'geo') {
      requiredlabels = ['Control_Statistics.number_controls'];
    } else if (type == 'team') {
      requiredlabels = [
        'status.on_duty',
        'status.off_duty',
        'status.on_break',
        'status.joint_operation',
        'status.accident',
        'General_View.team_statuses',
      ];
    }
    this.translateService
      .get(requiredlabels)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        translate => {
          this.labels = translate;
          this.displayLegend(type);
        },
        error => {
          console.error(error);
        },
      );
  }

  getPopupTranslateLables(type) {
    let requiredLabels = [];
    if (type == 'incident' || type == 'team') {
      requiredLabels = ['incidents.incident_type', 'incidents.status', 'incidents.description'];
    }
    this.translateService
      .get(requiredLabels)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        translate => {
          this.labels = translate;
        },
        error => {
          console.error(error);
        },
      );
  }

  private displayZone(zone) {
    zone.geometry.type = 'Polygon';
    const info = zone.properties.options;
    let popup = '';
    let zoneToAdd = new L.GeoJSON(zone, {
      style: () => {
        if (info.numberOfControls === 0) {
          return {
            fillColor: '#9b9b9b', // to fill zone with color
            color: '#6b6a6c', // to fill Boundaries
            fillOpacity: 0.3,
          };
        } else {
          if (info.fraudRate >= 70) {
            return {
              fillColor: '#d6322d', // to fill zone with color
              color: '#6b6a6c', // to fill Boundaries
              fillOpacity: 0.3,
            };
          } else if (info.fraudRate < 70 && info.fraudRate >= 30) {
            return {
              fillColor: '#f17e3a', // to fill zone with color
              color: '#6b6a6c', // to fill Boundaries
              fillOpacity: 0.3,
            };
          } else {
            return {
              fillColor: '#49a02e', // to fill zone with color
              color: '#6b6a6c', // to fill Boundaries
              fillOpacity: 0.3,
            };
          }
        }
      },
    });
    if (info) {
      if (info.cityName) {
        popup += `<div style="text-align: center; bottom: 13px; border-bottom: solid 2px #1c2831; position: relative;">
            <span class="name" style="color: #25aae1; "> ${info.cityName.toUpperCase()} </span>
         </div>
         <div>
          <i class="fa fa-check-square-o" aria-hidden="true" style="color: #25aae1"></i>
          <span style="color: white;"> ${this.translateService.instant('Control_Statistics.number_controls')} : ${
          info.numberOfControls
        }</span>
          </div>
          <div>
          <i class="fa fa-check-square-o" aria-hidden="true" style="color: #25aae1"></i>
          <span style="color: white;"> ${this.translateService.instant('Control_Statistics.number_contravention')} : ${
          info.numberOfContraventions
        }</span>
          </div>
          <div>
          <i class="fa fa-check-square-o" aria-hidden="true" style="color: #25aae1"></i>
          <span style="color: white;"> ${this.translateService.instant('Control_Statistics.fraud_rate')} : ${
          info.fraudRate
        }%</span>
          </div>`;
      }
      // if this is the selected region, then highlight the zone
      if (info.selected) {
        this.map.panTo(new L.LatLng(info.center.x, info.center.y));
        zoneToAdd.setStyle({
          fillOpacity: 0.7, // to fill zone with color
        });
      }
      zoneToAdd.bindPopup(popup, { className: 'popupCustom' });
    }

    // On zones hover => change style
    zoneToAdd.on('mouseover', function () {
      this.setStyle({
        fillOpacity: 0.7,
      });
    });
    zoneToAdd.on('mouseout', function () {
      this.setStyle({
        fillOpacity: 0.3,
      });
    });
    // on zone click => send city name to update sunburst chart data
    zoneToAdd.on('click', (e: any) => {
      const options = e.layer.feature.properties.options;
      this.center = latLng(options.center.x, options.center.y);
      this.mapDataChangedEmitter.emit(options.cityName);
    });
    this.layers.push(zoneToAdd);
  }

  private displayMarkerClusterGroups(data) {
    if (data) {
      const clusterMarkers = L.markerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        iconCreateFunction: cluster => {
          let childCount = cluster.getChildCount();
          let className = ' marker-cluster-';
          if (childCount < 10) {
            className += 'small';
          } else if (childCount < 100) {
            className += 'medium';
          } else {
            className += 'large';
          }
          return new L.DivIcon({
            html: '<div><span>' + childCount + '</span></div>',
            className: 'marker-cluster' + className,
            iconSize: new L.Point(40, 40),
          });
        },
      });
      for (const feature of data.features) {
        const marker = L.marker([feature.geometry.coordinates[0], feature.geometry.coordinates[1]], {
          icon: this.defaultIconObject,
          ...feature.properties.options,
        });
        const info = feature.properties.info;
        if (info) {
          let popup = `<div style="text-align: center; bottom: 13px; border-bottom: solid 2px #1c2831; position: relative;">
            <span class="name" style="color: #25aae1; "> ${info.title} </span>
         </div>
         <div>
          <i class="fa fa-check-square-o" aria-hidden="true" style="color: #25aae1"></i>
          <span style="color: white;"> ${this.translateService.instant('Control_Statistics.number_controls')} : ${
            info.nb_controls ? info.nb_controls : 0
          }</span>
          </div>`;
          marker.bindPopup(popup, { className: 'popupCustom', maxWidth: 500 });
        }
        clusterMarkers.addLayer(marker);
      }
      this.layers.push(clusterMarkers);
    }
  }
}
