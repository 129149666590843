export const applicationLangs: { name: string; code: string; flag: string }[] = [
  {
    name: 'FR',
    code: 'fr',
    flag: 'fi fi-fr',
  },
  {
    name: 'EN',
    code: 'en',
    flag: 'fi fi-us',
  },
];
