import { Pipe, PipeTransform } from '@angular/core';
import { Regex } from '../enums/regex.enum';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value instanceof Date) {
      const hours = `${value.getHours()}`;
      const minutes = `${value.getMinutes()}`;
      return `${hours.length > 1 ? hours : `0${hours}`}h${minutes.length > 1 ? minutes : `0${minutes}`}`;
    }
    if (!value || !value.match(Regex.TIME_hh_mm_ss)) {
      return value;
    }
    const timeSplited = value.split(':');
    return `${timeSplited[0]}h${timeSplited[1]}`;
  }
}
