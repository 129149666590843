<div mat-dialog-title class="dialog-title">
  <span>{{ 'incidents.select_coord' | translate }}</span>
</div>

<div mat-dialog-content class="min-height-70">
  <div style="height: 350px">
    <app-mapview [mapData]="mapData" [getLatLng]="true" (mapDataChangedEmitter)="getLatLng($event)"> </app-mapview>
  </div>
  <div class="coordinates" fxLayout fxLayoutAlign="start center" fxLayoutGap="30px" fxLayoutGap.xs="0">
    <div fxLayout="column" fxFlex="50">
      {{ 'AI_Configuration.network.definition.latitude' | translate }} : {{ map_center_lat }}
    </div>
    <div fxLayout="column" fxFlex="50">
      {{ 'AI_Configuration.network.definition.longitude' | translate }} : {{ map_center_lng }}
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayout fxLayoutAlign="space-between center">
  <button mat-stroked-button mat-dialog-close color="warn" (click)="cancel()" id="coord-picker-button-cancel">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-flat-button color="accent" (click)="onSubmit()" id="coord-picker-button-confirm">OK</button>
</div>
