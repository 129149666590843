import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../models/app-config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configuration: AppConfig;
  private httpClient: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  setConfig(): Promise<AppConfig> {
    return this.httpClient
      .get<AppConfig>('/assets/config.json')
      .toPromise()
      .then(config => (this.configuration = config));
  }

  readConfig(): AppConfig {
    return this.configuration;
  }
}
