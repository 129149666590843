import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { TAgent } from '../../modules/hr/models/agent.model';
import { StorageService } from './storage.service';
import { TokenService } from './token.service';
import { TokenDataModel } from '../../shared/models/token-data.model';

@Injectable()
export class AuthService {
  authToken: any;
  isAuthenticated = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private storageService: StorageService,
    private tokenService: TokenService,
  ) {}

  login(user): Observable<any> {
    return this.apiService.post('/auth/login', user);
  }

  forgetPassword(options): Observable<any> {
    return this.apiService.post('/auth/forget-password', options);
  }

  autoAuthUser() {
    if (!this.loggedIn()) {
      this.logOut();
      return;
    }
    const authData = this.getAuthData();
    this.onSuccessAuth(authData);
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  logOut(): Observable<any> {
    return this.apiService.put('/auth/logout');
  }

  storeUserData(token): void {
    this.tokenService.save(token);
    this.onSuccessAuth({ token });
  }

  getAuthData() {
    const token = this.tokenService.get();
    if (!token) {
      return;
    }
    return {
      token: token,
    };
  }

  getTokenData() {
    const helper = new JwtHelperService();
    const token = this.tokenService.get();
    const decodedToken = helper.decodeToken(token);
    return decodedToken as TokenDataModel;
  }

  private onSuccessAuth({ token }) {
    this.authToken = token;
    this.isAuthenticated = true;
  }

  private loggedIn(): boolean {
    const helper = new JwtHelperService();
    const token = this.tokenService.get();
    if (token) {
      return !helper.isTokenExpired(token);
    } else {
      return false;
    }
  }

  generateToken(oldAgent: TAgent, newAgent: TAgent) {
    return this.apiService.post('/auth/generate-token', { oldAgent, newAgent });
  }

  putResetPassword(body) {
    return this.apiService.put('/auth/password/reset', body);
  }
}
