import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours',
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(value: number, displayMinuteLabel: boolean = false): string {
    if (typeof value !== 'number') {
      return null;
    }
    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value % 60);
    const hoursParsed = hours < 10 ? `0${hours}` : hours;
    const minutesParsed = minutes < 10 ? `0${minutes}` : minutes;
    const minuteLabel = displayMinuteLabel ? 'm' : '';
    return `${hoursParsed}h${minutesParsed}${minuteLabel}`;
  }
}
