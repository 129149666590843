import { Route } from '../models/route';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface EditTripResponse {
  network: string;
  tripId: string;
  agentId: number;
}

export interface RouteRepository {
  getRoutesByDate(date: string): Observable<Route[]>;
  updateTrip(params: {
    routeId: string;
    tripId: string;
    agentId: number | null;
  }): Observable<EditTripResponse | undefined>;
}

export const ROUTE_REPOSITORY: InjectionToken<RouteRepository> = new InjectionToken<RouteRepository>(
  'ROUTE_REPOSITORY',
);
