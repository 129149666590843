import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SortByPipe } from './sort-by.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { MinutesToHoursPipe } from './minutes-to-hours.pipe';

const pipes = [SortByPipe, TimeFormatPipe, MinutesToHoursPipe];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class SharedPipesModule {}
