import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AfterContentInit, ContentChild, Directive, OnDestroy } from '@angular/core';
import { MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { FormControlName } from '@angular/forms';

@Directive({
  selector: 'mat-date-range-input',
})
export class UpdateDateRangeValueAndValidityFixerDirective implements AfterContentInit, OnDestroy {
  @ContentChild(MatStartDate, { read: FormControlName }) startDateControlName: FormControlName;
  @ContentChild(MatEndDate, { read: FormControlName }) endDateControlName: FormControlName;

  private readonly destroyed$ = new Subject<void>();

  ngAfterContentInit(): void {
    this.startDateControlName.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(() => setTimeout(() => this.endDateControlName.control.updateValueAndValidity()));

    this.endDateControlName.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(() => setTimeout(() => this.startDateControlName.control.updateValueAndValidity()));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
