import { inject, Injectable } from '@angular/core';
import { AgentScheduleRepository } from '@drivers';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent';
import { ApiService } from '../../../core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AgentScheduleHttpRepository implements AgentScheduleRepository {
  private apiService = inject(ApiService);

  private url = '/bus-planning/agent-planned';

  getAgent(date: string): Observable<Agent[]> {
    return this.apiService.get(this.url, { date });
  }
}
