import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CropperSettings } from 'ngx-img-cropper';
import { ConfigService } from '../../services/config.service';

export interface DialogAvatarEditorData {
  image: string;
}

@Component({
  selector: 'app-avatar-editor',
  templateUrl: './avatar-editor.component.html',
  styleUrls: ['./avatar-editor.component.scss'],
})
export class AvatarEditorComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  cropperSettings = new CropperSettings();
  dataImage: any;
  apiUrl: string;

  constructor(
    public dialogRef: MatDialogRef<AvatarEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogAvatarEditorData,
    private configService: ConfigService
  ) {
    this.apiUrl = `${this.configService.readConfig().apiUrl}/`;
  }

  ngOnInit() {
    this.cropperSettings.width = 150;
    this.cropperSettings.height = 135;
    this.cropperSettings.croppedWidth = 150;
    this.cropperSettings.croppedHeight = 150;
    this.cropperSettings.canvasWidth = 300;
    this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.rounded = true;
    this.dataImage = {};
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  saveImage(): void {
    if (this.dataImage.image) {
      this.dialogRef.close(this.dataImage.image);
    }
  }
}
