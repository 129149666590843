import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-coord-picker',
  templateUrl: './coord-picker.component.html',
  styleUrls: ['./coord-picker.component.scss'],
})
export class CoordPickerComponent implements OnInit {
  mapData;
  map_center_lat: number;
  map_center_lng: number;

  constructor(
    public dialogRef: MatDialogRef<CoordPickerComponent>,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.mapData = this.data.mapData;
    this.map_center_lat = this.data.lat;
    this.map_center_lng = this.data.lng;
    // console.log('dialog map data ::', JSON.parse(this.data.mapData));
  }

  getLatLng(event) {
    this.map_center_lat = event.lat;
    this.map_center_lng = event.lng;
    this.changeDetectorRef.detectChanges();
  }

  onSubmit() {
    this.dialogRef.close({ lat: this.map_center_lat, lng: this.map_center_lng });
  }

  cancel() {
    this.map_center_lat = null;
    this.map_center_lng = null;
  }
}
