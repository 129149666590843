import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { Agent } from '../models/agent';

export interface AgentScheduleRepository {
  getAgent(date: string): Observable<Agent[]>;
}

export const AGENT_REPOSITORY: InjectionToken<AgentScheduleRepository> = new InjectionToken<AgentScheduleRepository>(
  'AGENT_REPOSITORY',
);
