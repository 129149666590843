import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'underscore';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(value: any[], order = '', column = ''): any {
    let sortedItems;
    if (!value || order === '' || !order) {
      return value;
    }
    if (value.length <= 1) {
      return value;
    }
    if (!column || column === '') {
      sortedItems = sortBy(value, obj => obj.toLowerCase());
      return sortedItems;
    } else {
      sortedItems =
        order === 'asc'
          ? sortBy(value, obj => obj[column].toLowerCase())
          : sortBy(value, obj => obj[column].toLowerCase()).reverse();
      const findIndexOther = sortedItems.findIndex(item => item[column] === 'Autre' || item[column] === 'Other');
      if (findIndexOther !== -1) {
        // remove `from` item and store it
        const otherObj = sortedItems.splice(findIndexOther, 1)[0];
        // insert stored item into position `to`
        sortedItems.splice(sortedItems.length, 0, otherObj);
      }
      return sortedItems;
    }
  }
}
