import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.getTokenData();
    if (currentUser) {
      // check if route is restricted by role
      if (
        route.data.roles &&
        (!currentUser.rights.hasOwnProperty(route.data.roles) || !currentUser.rights[route.data.roles])
      ) {
        // role not authorised so redirect to home page
        this.router.navigate(['/dashboard']).then(() => console.log('Unauthorised'));
        return false;
      }
      // authorised so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/signin'], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
