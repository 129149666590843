<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">
      <!-- App Logo -->
      <div class="branding">
        <img src="assets/images/login/octocity_logo_dark_bg.svg" alt="" class="app-logo" />
        <!--<img src="assets/images/logo.png" alt="" class="app-logo">
                <span class="app-logo-text">DATATEGY</span>-->
        <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
      </div>

      <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle">
      </app-sidenav>
    </div>

    <div class="footer">
      <p>
        {{ 'common.powered_by' | translate }} ©<a href="https://datategy.net/" target="_blank">Datategy</a> 2020 v{{
          version
        }}
      </p>
    </div>
  </div>
</div>
