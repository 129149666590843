<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="header-side-button-hide-open-side-nav"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>
  <span fxFlex></span>

  <!-- Rights Button-->
  <button
    *ngIf="tokenData.rights.admin"
    mat-stroked-button
    [routerLink]="['/rights']"
    class="mr-1"
    id="header-side-button-rights">
    <img src="assets/images/key_dark_blue.svg" width="17px" />
    {{'Rights.title' | translate}}
  </button>

  <!-- Network Switcher-->
  <mat-select
    style="width: 150px"
    [placeholder]="'AI_Configuration.network.title' | translate"
    [value]="agentLoginsSelected"
    (selectionChange)="onChangeNetwork($event.value, agentLoginsSelected)"
    id="header-side-button-network-switcher">
    <mat-option
      *ngFor="let agentLogin of agentsLogins"
      [value]="agentLogin"
      [id]="'header-side-button-network-dropdown-' + agentLogin.network">
      {{agentLogin.network}}
    </mat-option>
  </mat-select>

  <!-- Language Switcher -->
  <button mat-button [matMenuTriggerFor]="menu" id="header-side-button-language-switcher">
    <span class="flag-icon {{currentLang.flag}} mr-05"></span>
    <span>{{currentLang.name}}</span>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let lang of availableLangs"
      (click)="setLang(lang)"
      [id]="'header-side-button-language-dropdown-' + lang.name">
      <span class="flag-icon mr-05 {{lang.flag}}"></span>
      <span>{{lang.name}}</span>
    </button>
  </mat-menu>

  <!--Profile Button-->
  <button
    mat-icon-button
    [routerLink]="['/profile']"
    class="topbar-button-right"
    matTooltip="{{'Profile.title'| translate}}"
    id="header-side-button-profil">
    <mat-icon>account_box</mat-icon>
  </button>

  <!-- Notification toggle button -->
  <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
    id="header-side-button-notification">
    <mat-icon>notifications</mat-icon>
    <span *ngIf="notifsNumber" class="notification-number mat-bg-warn">{{notifsNumber}}</span>
  </button>

  <!--Logout Button-->
  <button
    mat-icon-button
    matTooltip="{{'common.logout'| translate}}"
    class="topbar-button-right"
    (click)="logOut()"
    id="header-side-button-logout">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>
