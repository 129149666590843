<h1 mat-dialog-title>{{ 'Agents.edit_photo' | translate }}</h1>
<mat-dialog-content>
  <div fxLayout fxLayout.lt-lg="column" fxLayoutAlign="space-evenly" id="avatar-editor">
    <div fxFlex="60" fxFlex.lt-lg="100">
      <img-cropper
        [image]="dataImage"
        [settings]="cropperSettings"
        style="outline: none"
        id="avatar-editor-image"></img-cropper>
    </div>
    <div fxFlex="30" fxFlex.lt-lg="100">
      <h5>{{ 'Agents.preview' | translate }}</h5>
      <div>
        <mat-icon *ngIf="!dataImage.image && !data.image">account_circle</mat-icon>
        <img *ngIf="!dataImage?.image && data.image" [src]="data.image" class="img-wrapper" />
        <img
          *ngIf="dataImage?.image"
          [src]="dataImage.image"
          [width]="cropperSettings.croppedWidth"
          [height]="cropperSettings.croppedHeight"
          class="img-wrapper" />
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions fxLayout fxLayoutAlign="space-between center">
  <button mat-stroked-button color="warn" mat-dialog-close id="avatar-editor-button-cancel">
    {{ 'common.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="accent"
    [disabled]="!dataImage?.image"
    (click)="saveImage()"
    id="avatar-editor-button-confirm">
    {{ 'common.save' | translate }}
  </button>
</div>
